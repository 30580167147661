button[title="Next"], button[title="Got it"] {
    background-color: #004251 !important;
    padding: 10px 32px !important;
    font-size: 15px !important;
    line-height: 18px !important;
}

button[title="Previous"], button[title="Later"] {
    background-color: transparent !important;
    padding: 10px 32px !important;
    font-size: 15px !important;
    line-height: 18px !important;
    color: #00B59A !important;
}

button[title="Close"] {
    padding-top: 33px !important;
    padding-right: 38px !important;
}

button[title="Next"]:focus, button[title="Got it"]:focus, button[title="Previous"]:focus, button[title="Skip"]:focus {
    outline: none !important;
}


.react-joyride__tooltip > div > div {
    padding: 0 !important;
}

.react-joyride__spotlight {
    border-radius: 10px !important;
}

#root {
    height: 100vh;
}

.rpv-core__inner-pages {
    overflow: hidden;
}